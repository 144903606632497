import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "prompt"
}


export default {
  props: {
    routerInfo: {
        default: function () {
            return null
        },
        type: Object
    }
},
  setup(__props) {



return (_ctx, _cache) => {
  const _component_el_menu_item = _resolveComponent("el-menu-item")

  return (_openBlock(), _createBlock(_component_el_menu_item, {
    index: __props.routerInfo.name,
    disabled: __props.routerInfo.disabled
  }, {
    title: _withCtx(() => [
      _createElementVNode("span", _hoisted_2, [
        _createTextVNode(_toDisplayString(__props.routerInfo.title) + " ", 1),
        (__props.routerInfo.prompt)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.routerInfo.prompt), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: "iconfont",
        innerHTML: __props.routerInfo.meta.icon
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["index", "disabled"]))
}
}

}