import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-038ab806"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sales-user" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "cont" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { style: {"white-space":"nowrap"} }

import { ArrowDown } from '@element-plus/icons-vue';
import { getCurrentInstance, provide } from 'vue'

export default {
  setup(__props) {

const { proxy: { $filters: { concatImg } } } = getCurrentInstance()
const userInfo = localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')) : {};

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _unref(concatImg)(_unref(userInfo).avatar),
      alt: "",
      class: "avatar"
    }, null, 8, _hoisted_2),
    _createElementVNode("ul", _hoisted_3, [
      _createElementVNode("li", null, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(userInfo).name || _unref(userInfo).phone), 1)
      ]),
      _createElementVNode("li", _hoisted_5, _toDisplayString(_unref(userInfo).companyName), 1)
    ])
  ]))
}
}

}