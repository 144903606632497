import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition } from "vue"

const _hoisted_1 = {
  style: {"background":"#fff"},
  class: "aside-box"
}

import { $apiPromptInfo } from "@/api/modules/sales";
import AsideComponent from "./asideComponent/index.vue";
import { ref, watch, getCurrentInstance, inject, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { AsideRoutes } from "@/router/modules/SalesRoutes";
import { $apiAdminPermissions } from '@/api/modules/user';

export default {
  setup(__props) {

const reload = inject(['reload'])
const { proxy: { $msg } } = getCurrentInstance()
const token = localStorage.getItem("token");
const admin_show = ref(false)
const route = useRoute();
const router = useRouter();
const active = ref("");
const isCollapse = ref(false);
const active_color = ref("")
const salesroutes = ref(AsideRoutes)
const menuList = computed(() => salesroutes.value.map(item =>Object.assign(item, { disabled: item.meta.permissions ? !admin_show.value : false })));

//管理权限
async function adminPermissions() {
  const data = await $apiAdminPermissions({ token: localStorage.getItem('token') })
  if (data.status) {
    admin_show.value = true
  } else {
    admin_show.value = false
  }
}
//消息提示
async function userPrompt() {
  const { status, data } = await $apiPromptInfo({ token });
  if (status) {
    data.Orders && AsideRoutes.value.forEach(item => item.path == "/sales/messages" && (item['prompt'] = data.Chat))
    data.Contract && AsideRoutes.value.forEach(item => item.path == "/sales/order" && (item['prompt'] = data.Orders) && (item.children[0]['prompt'] = data.Orders))
    data.Chat && AsideRoutes.value.forEach(item => item.path == "/sales/contract" && (item['prompt'] = data.Contract) && (item.children[0]['prompt'] = data.Contract))
  }
}
const selectMenuItem = (index) => {
  const len = AsideRoutes.filter(item => item.name == index && item.meta.permissions).length
  // auth
  // router.push({ name: index })
  admin_show.value ? router.push({ name: index }) : len ? $msg.warn("暂无权限") && reload() : router.push({ name: index })
};
const initPage = () => {
  active.value = route.name;
  active_color.value = route.meta.permissions ? "#cc0000" : "#3377FF"
};
initPage();
adminPermissions()
// token && userPrompt()
watch(route, () => {
  active.value = route.name;
  active_color.value = route.meta.permissions ? "#cc0000" : "#3377FF"
});
document.body.style = `min-width:1080px;overflow-x:hidden`
window.addEventListener('resize',function(e){
  if (e.target.innerWidth<=1080) {
    isCollapse.value = true
  }else{
    isCollapse.value = false
  }
})

return (_ctx, _cache) => {
  const _component_el_menu = _resolveComponent("el-menu")
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_scrollbar, null, {
      default: _withCtx(() => [
        _createVNode(_Transition, {
          duration: { enter: 800, leave: 100 },
          mode: "out-in",
          name: "el-fade-in-linear"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_menu, {
              collapse: isCollapse.value,
              "collapse-transition": false,
              "default-active": active.value,
              "background-color": "#fff",
              "active-text-color": active_color.value,
              "text-color": "#333",
              class: "el-menu-vertical",
              "unique-opened": "",
              onSelect: selectMenuItem
            }, {
              default: _withCtx(() => [
                (active_color.value)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(menuList), (item) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (!item.hidden)
                          ? (_openBlock(), _createBlock(AsideComponent, {
                              key: item.name,
                              "router-info": item,
                              class: _normalizeClass([active.value == item.name ? 'router_active' : '', item.meta.permissions ? 'admin_hover' : null, active.value == item.name && item.meta.permissions ? 'admin_active' : '',])
                            }, null, 8, ["router-info", "class"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["collapse", "default-active", "active-text-color"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

}